<template>
	<section class="permission">
		<!--工具条-->
		<div class="toolbar flex-between">
			<el-form :inline="true" :model="form">
				<el-form-item label="账号">
					<el-input v-model.trim="form.store_id" placeholder="店主账号" clearable></el-input>
				</el-form-item>
				<el-form-item label="时间">
					<el-date-picker v-model.trim="time" type="daterange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!--列表-->
		<el-table class="mb20" :data="listData" highlight-current-row v-loading="listLoading">
			<el-table-column class="plr10" prop="name" label="渠道名称" width="180">
			</el-table-column>
			<el-table-column prop="amount" label="总流水" sortable width="150">
			</el-table-column>
			<el-table-column prop="alipay_amount" label="支付宝流水" width="150">
			</el-table-column>
			<el-table-column prop="wx_amount" label="微信流水" width="150">
			</el-table-column>
			<el-table-column prop="union_amount" label="银联" width="150">
			</el-table-column>
			<el-table-column prop="rate" label="费率(百分之)" width="150" >
			</el-table-column>
			<el-table-column prop="service_price" label="服务费" width="150">
			</el-table-column>
			<el-table-column prop="fenrun_price" label="分润" sortable>
			</el-table-column>
		</el-table>
		<div class="flex fw fs16">
			<span class="plr10 w180">合计</span>
			<span class="plr10 w150">{{totalData.sale_amount}}</span>
			<span class="plr10 w150">{{totalData.alipay_sale_amount}}</span>
			<span class="plr10 w150">{{totalData.wx_sale_amount}}</span>
			<span class="plr10 w150">{{totalData.union_sale_amount}}</span>
			<span class="plr10 w150"></span>
			<span class="plr10 w150">{{totalData.service_amount}}</span>
			<span class="plr10 w150">{{totalData.fenrun_amount}}</span>
		</div>
	</section>
</template>

<script>
let _t = undefined
import { statistics } from '../../api/api';
export default {
	name: 'channelWater',
	data() {
		return {
			time:[],
			form: {
				start_date:'',
				end_date:'',
				store_id: ''
			},
			totalLs: "",
			totalFr: "",
			totalData: {},
			listData: [],
			listLoading: false
		}
	},
	mounted() {
		_t = this
		_t.getList();
	},
	methods: {
		getList() {
			// console.log(this.time)
			_t.listLoading = true;
			_t.form.start_date = _t.time[0]
			_t.form.end_date = _t.time[1]
			statistics(_t.form).then((res) => {
				_t.listLoading = false;
				if(res.code == 1){
					_t.totalData = res.data
					_t.listData = res.data.list;
				}else {
					_t.$notify({
						title: '错误',
						message: res.msg,
						type: 'error'
					});
				}
			});
		},			
	}
}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
	.mInput{
		.el-input__inner{ width: 60px; text-align: center; height:32px; line-height:32px;  }
	}
	.el-table--enable-row-transition .el-table__body td.el-table__cell{ padding: 15px 10px; }
	.w150{ width: 150px; }
	.w180{ width: 180px; }
	.plr10{ padding: 0 10px;}
</style>